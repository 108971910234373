import { useState, useEffect } from 'react';
import axios from 'axios';

const API_URL_HARDWARE = 'https://token.etiicos.in/api/admin/hardware';
const API_URL_DOCTORS = 'https://token.etiicos.in/api/admin/doctors';

// Define TypeScript Interfaces
interface Hospital {
    _id: string;
    name: string;
}

interface Doctor {
    hospitalId: string;
    _id: string;
    name: string; 
}
interface Hardware {
    id: string;
    macAddress: string;
    hospitalId: string | Hospital;  // Allow both string and object
    doctorId: string | Doctor;  // Allow both string and object
}


// Custom Hook to Fetch Hardware Data
export const useHardware = () => {
    const [hardwareList, setHardwareList] = useState<Hardware[]>([]);
    const [hospitals, setHospitals] = useState<Hospital[]>([]);
    const [doctors, setDoctors] = useState<Doctor[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Fetch Hardware Data
    const fetchHardware = async () => {
        setLoading(true);
        try {
            const response = await axios.get(API_URL_HARDWARE);
            if (response.data.success) {
                const formattedData: Hardware[] = response.data.response.map((item: any) => ({
                    id: item._id,
                    macAddress: item.mac_address,
                    hospitalId: {
                        _id: item.hospital_id?._id || 'N/A',
                        name: item.hospital_id?.name || 'N/A',
                    },
                    doctorId: {
                        _id: item.doctor_id?._id || 'N/A',
                        name: item.doctor_id?.name || 'N/A', 
                    },
                }));
                setHardwareList(formattedData);
            } else {
                setError('Failed to fetch hardware data');
            }
        } catch (err) {
            setError('Error fetching hardware data');
        }
        setLoading(false);
    };

    // Fetch Doctors and Hospitals
    const fetchDoctorsAndHospitals = async () => {
        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                throw new Error('Unauthorized: No token found');
            }

            const response = await axios.get(API_URL_DOCTORS, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.data.success) {
                const allHospitals = response.data.doctors
                    .map((doctor: any) => doctor.hospital)
                    .flat();
                setHospitals(allHospitals);
                setDoctors(response.data.doctors);
            }
        } catch (error) {
            console.error('Error fetching doctors:', error);
        }
    };

    useEffect(() => {
        fetchHardware();
        fetchDoctorsAndHospitals();
    }, []);

    return { hardwareList, hospitals, doctors, loading, error, fetchHardware };
};
