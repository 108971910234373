import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useDoctor } from "../../hooks/useDoctor";
import BreadCrumbs from "../../components/BreadCrumbs";

import Chip from "@mui/material/Chip";
import { CallCard } from "../../components/CallCard";
import { ActiveCallRecord } from "../../hooks/useActiveCall";
import { useUserAuth } from "../../provider/AuthProvider";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 450,
    },
  },
};


const ActiveCallsLayout = ({
  loading,
  activeCalls,
  endCall,
}: {
  loading: boolean;
  activeCalls: ActiveCallRecord[];
  endCall: (id: string) => void;
}) => {
  const { getAllDepartments, departments } = useDoctor();
  const { user } = useUserAuth();
  const [selectedId, setSelectedId] = React.useState<string[]>([]);
  const [filteredActiveCalls,setFilteredActiveCalls] = React.useState<ActiveCallRecord[]>([...activeCalls])

  // this runs only on initial render since activeCalls gets changed only once
  // on changing the dropdown this doesn't calls , as filteredActiveCalls state is independent of this
  React.useEffect(()=>{
    setFilteredActiveCalls(activeCalls)
  },[activeCalls])

  React.useEffect(() => {
    getAllDepartments();
  }, [getAllDepartments]);

  const handleChange = (event: SelectChangeEvent<typeof selectedId>) => {
    const {
      target: { value },
    } = event;
    setSelectedId(typeof value === "string" ? value.split(",") : value);
    // this function handles filtering logic
    filterDepartmentFunction(event)
  };

  const filterDepartmentFunction = (event:SelectChangeEvent<typeof selectedId>) => {

    let selectedDepartments:any = []
    // if department selected is more than or equal to 1
    if((event?.target?.value).length>=1){
      departments.map((department)=>{
        if(event.target.value.includes(department.value)){
          selectedDepartments.push(department.label)
        }
      })
    }else {
      // everything is unchecked
      departments.map((department)=>{
          selectedDepartments.push(department.label)
      })
    }
    let result:ActiveCallRecord[] = []
    for(let i = 0;i<activeCalls.length;i++){
      if(selectedDepartments.includes(activeCalls[i]['department'])){
        result.push(activeCalls[i])
      }
    }
    setFilteredActiveCalls(result)
  }

  return (
    <Box>
      <BreadCrumbs
        data={["Home", "Token", "Active Calls"]}
        title="Active Calls"
      />
      <Typography sx={{ marginTop: "24px", marginBottom: "24px" }} variant="h4">
        Calls
      </Typography>
      <FormControl fullWidth sx={{ mb: 3 }}>
        <InputLabel id="multiple-checkbox-label">Select Department</InputLabel>
        <Select
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={selectedId}
          onChange={handleChange}
          input={<OutlinedInput label="Select Department" />}
          renderValue={(selected) => {
            // show the selected departments
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={
                      departments.find((item) => item.value === value)?.label
                    }
                  />
                ))}
              </Box>
            );
          }}
          MenuProps={MenuProps}
        >
          {departments?.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <Checkbox checked={selectedId.indexOf(item.value) > -1} />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {filteredActiveCalls?.map((call) => (
        <CallCard
          key={call.id}
          loading={loading}
          call={call}
          endCall={endCall}
        />
      ))}
    </Box>
  );
};

export default ActiveCallsLayout;
