import React from "react";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import DataGridContainer from "../../components/DataGrid";
import BreadCrumbs from "../../components/BreadCrumbs";
import { CallRecord } from "../../hooks/useCallLogs";
import { useUserAuth } from "../../provider/AuthProvider";
import { useSocketContext } from "../../provider/SocketProvider";
import { isValidJSON } from "../../utils/checkJson";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
    //Reduce "ID" column to 8 bit.
    renderCell: (row) => {
      return <>{String(row?.row?.id).slice(0, 4)}</>;
    },
  },
  {
    field: "message",
    headerName: "Content",
    width: 150,
  },
  {
    field: "roomNo",
    headerName: "Room number",
    width: 100,
  },
  {
    field: "department",
    headerName: "Department",
    width:100,
  },
  {
    field: "doctorId",
    headerName: "Doctor Name",
    width:150,
    renderCell: (row) => {
      return <>{String(row?.row?.doctorId.name)}</>;
    },
  },
  {
    field: "startTime",
    headerName: "Start time",
    width:150,
    renderCell: (row) => {
      let convertedStartTime: any = new Date(row.row.startTime);

      let output = "-";
      if (row.row.startTime) {
        output = convertedStartTime.toLocaleDateString("en-us", {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        });
      }

      return <>{output}</>;
    },
  },
  {
    field: "endTime",
    headerName: "End time",
    width:150,
    renderCell: (row) => {
      let convertedEndTime: any = new Date(row.row.endTime);
      let output = "-";
      if (row.row.endTime) {
        output = convertedEndTime.toLocaleDateString("en-us", {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        });
      }

      return <>{output}</>;
    },
  },
  {
    field: "duration",
    headerName: "Duration",
    width:150,
    // adding duration logic
    renderCell: (row) => {
      let convertedEndTime: any = new Date(row.row.endTime).toLocaleTimeString(
        "en-us",
        { minute: "numeric", hour: "numeric", second: "numeric", hour12: false }
      );
      let convertedStartTime: any = new Date(
        row.row.startTime
      ).toLocaleTimeString("en-us", {
        minute: "numeric",
        hour: "numeric",
        second: "numeric",
        hour12: false,
      });
      let callDuration: any = convertedEndTime.split(":");
      let callDurationStartTime = convertedStartTime.split(":");
      let endTimeSecond = parseInt(callDuration[2]);
      let endTimeMinute = parseInt(callDuration[1]);
      let endTimeHour = parseInt(callDuration[0]);
      let startTimeSecond = parseInt(callDurationStartTime[2]);
      let startTimeMinute = parseInt(callDurationStartTime[1]);
      let startTimeHour = parseInt(callDurationStartTime[0]);

      let result = `${Math.abs(endTimeHour - startTimeHour)} H : ${Math.abs(
        endTimeMinute - startTimeMinute
      )}M : ${Math.abs(endTimeSecond - startTimeSecond)}S `;
      return <>{result}</>;
    },
  },
  {
    field: "endBy",
    headerName: "Ended By",
    width: 100,
  },
];

const CallLogsLayout = ({
  callLogs,
}: {
  callLogs: CallRecord[];
  name?: string;
}) => {
  const { user } = useUserAuth();
  const { lastMessage } = useSocketContext();

  React.useEffect(() => {
    if (lastMessage && isValidJSON(lastMessage.data)) {
      const message = lastMessage.data;
      const msg = JSON.parse(message);
      if (
        msg.topic === "active_call" &&
        user?.id === msg.hospitalId &&
        msg.from === "doctor"
      ) {
        // reload the page
        //const audio = new Audio(require("../../assets/notification.wav"));
        //audio.play();
      }
    }
  }, []);
  return (
    <Box>
      <BreadCrumbs data={["Home", "Token", "Call Logs"]} title="Call Logs" />

      <DataGridContainer columns={columns} rows={callLogs} />
    </Box>
  );
};

export default CallLogsLayout;
