import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment';
import BreadCrumbs from '../../components/BreadCrumbs';
import { useUserAuth } from '../../provider/AuthProvider';
import { ItemDetails, useDoctorList } from '../../hooks/useDoctorList';
import { usePatient } from '../../hooks/usePatient';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSettings } from '../../hooks/useSettings';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { useDoctorSchedule } from '../../hooks/useDoctorSchedule';
import { useReactToPrint } from "react-to-print";
import { theme } from "../../theme/createTheme";
import { ThemeProvider } from "@mui/material/styles";
import './PatientRegistrationLayout.css';

const gender = [{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }, { label: 'Other', value: 'other' }];

const PatientRegistrationLayout = () => {
  const { user } = useUserAuth();
  const { getDoctorList, doctorList } = useDoctorList();
  const { loading, registerPatient, registeredPatientResponse } = usePatient();
  const [filterDoctor, setFilterDoctor] = useState<ItemDetails[]>([]);
  const [selectedDoctor, setSelectedDoctor] = useState<any>({});
  const { settingsData, getCustomRegisters } = useSettings();
  const [searchToken, setSearchToken] = useState<string>('');
  const [customRegistersData, setCustomRegistersData] = useState<any>([]);
  const [dynamicFieldValue, setDynamicFieldValue] = useState<any>({});
  const [toastId, setToastId] = useState<string | number | null>(null);
  const [prevData, setPrevData] = useState();
  const { viewDoctorSchedule, viewDoctorScheduleData } = useDoctorSchedule();
  const [patientData, setPatientData] = useState<any>(null);
  const [loadingData, setLoadingData] = useState(false);
  useEffect(() => {
    getDoctorList();
    getCustomRegisters();
  }, []);

  useEffect(() => {
    if (registeredPatientResponse && toastId !== null) {
      toast.update(toastId, { render: <CustomToast setSelectedDoctor={setSelectedDoctor} selectedDoctor={selectedDoctor} registeredData={prevData} registeredPatientResponse={registeredPatientResponse} user={user} /> });
    }
  }, [registeredPatientResponse]);

  useEffect(() => {
    setCustomRegistersData(settingsData);
  }, [settingsData]);

  const validationSchema = yup.object().shape({
    date: yup.string().required('Date is required'),
    name: yup.string().required('Full Name is required'),
    age: yup.string().required('Age is required'),
    slotId: yup.string().required('Slot is required'),
    phone: yup.string()
      .matches(/^[0-9]+$/, 'Phone Number must be only digits')
      .min(10, 'Phone Number must be at least 10 digits')
      .max(15, 'Phone Number must be at most 15 digits')
      .required('Phone Number is required'),
    department: yup.string().required('Department is required'),
    gender: yup.string().required('Gender is required'),
    doctor: yup.string().required('Doctor is required'),
  });

  const { control, handleSubmit, formState: { errors }, reset, setValue, getValues, watch } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      date: '',
      name: '',
      slotId: '',
      age: '',
      phone: '',
      department: '',
      gender: '',
      doctor: '',
    },
  });

  const watchedDate = watch('date');
  const watchedDoctor = watch('doctor');
  const watchedDepartment = watch('department');

  useEffect(() => {
    if (watchedDoctor) {
      viewDoctorSchedule(watchedDoctor);
    }
  }, [watchedDoctor]);

  const handleDoctorChange = (e: any) => {
    const selectedId = e.target.value as string;
    const selectedDoctor = doctorList.find((d) => d.id === selectedId);
    if (selectedDoctor) {
      setValue('doctor', selectedId, { shouldValidate: true });
      setSelectedDoctor([selectedDoctor]);
      viewDoctorSchedule(selectedDoctor.id);
    }
  };

  const handleDateChange = (e: any) => {
    setValue('date', e.target.value, { shouldValidate: true });
  };

  const onSubmit = async (data: any) => {
    try {
      const utcDate = moment(data.date).format('YYYY-MM-DD');
      const updatedData = { ...data, date: utcDate, meta: dynamicFieldValue };
      await registerPatient(updatedData);
      setPrevData(updatedData);
      const newToastId = toast(<CustomToast setSelectedDoctor={setSelectedDoctor} selectedDoctor={selectedDoctor} registeredData={updatedData} user={user} />, { autoClose: false });
      setToastId(newToastId);
      reset();
      setDynamicFieldValue({});
    } catch (error) {
      console.error(error);
    }
  };const fetchPatientByToken = async (id: string) => {
    setLoadingData(true);

    try {
        let authToken = localStorage.getItem("authToken"); 

        if (!authToken) {
            alert("Unauthorized! Please log in.");
            setLoadingData(false);
            return;
        }

        authToken = authToken.trim(); 

      
        if (!id || id.trim() === "" || id.includes("{")) {
            alert("Invalid patient ID!");
            setLoadingData(false);
            return;
        }

       
        console.log("Fetching patient with ID:", id);
        console.log("Authorization Token:", authToken);

        
        const url = `https://token.etiicos.in/api/patient/${encodeURIComponent(id)}`;
        console.log("API Request URL:", url); 

        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authToken}`,
            },
        });

    
        if (response.status === 403) {
            alert("Access Forbidden: Invalid token or insufficient permissions.");
            
          
            const errorData = await response.json().catch(() => null);
            console.error("403 Error Response:", errorData);

            if (errorData?.message?.toLowerCase().includes("token expired")) {
                alert("Your session has expired. Please log in again.");
                localStorage.removeItem("authToken"); 
                window.location.href = "/login"; 
            }

            setLoadingData(false);
            return;
        }

        // Check if the response is OK
        if (!response.ok) {
            const errorResponse = await response.json().catch(() => null);
            console.error("API Error:", errorResponse);
            alert(`Error: ${errorResponse?.message || "Failed to fetch patient data"}`);
            setLoadingData(false);
            return;
        }

        const data = await response.json();

        if (data.success && data.data) {
            const patient = data.data;

            // Populate form fields with API response
            setValue("name", patient.name);
            setValue("gender", patient.gender);
            setValue("phone", patient.phone);
            setValue("age", patient.age.toString());

            setPatientData(patient);
        } else {
            alert(data.message || "Patient not found");
            setPatientData(null);
        }
    } catch (error) {
        console.error("Error fetching patient data:", error);
        alert("Error fetching patient data");
        setPatientData(null);
    } finally {
        setLoadingData(false);
    }
};

  
  // Handle search button click
  const handleSearchClick = () => {
    if (searchToken) {
      fetchPatientByToken(searchToken); // Fetch patient data when search button is clicked
    } else {
      alert("Please enter a token number!");
    }
  };
  
  const handleClearForm = () => {
    reset();
    setDynamicFieldValue({});
    setSelectedDoctor({});
  };

  const onChangeHandler = (e: any) => {
    setDynamicFieldValue((prev: any) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleDepartmentChange = (event: any) => {
    const departmentId = event.target.value as string;
    setValue('department', departmentId, { shouldValidate: true });
    const filterDoc = doctorList.filter(item => item.departmentId === departmentId);
    setFilterDoctor(filterDoc);
  };
  

  return (
    <Stack direction="column" spacing={3} sx={{ width: "100%", p: { xs: 2, sm: 2, md: 5 } }}>
      <BreadCrumbs data={['Home', 'Token', 'Patient Registration']} title="Patient Registration" />
      <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left', gap: 2 ,padding: "1%", }}>
      <TextField
        id="search-token"
        label="Search by Patient ID"
        variant="outlined"
        value={searchToken}
        onChange={(e) => setSearchToken(e.target.value)}
        onBlur={() => fetchPatientByToken(searchToken)}
        autoComplete="off"
      />
      <Button variant="contained" onClick={() => fetchPatientByToken(searchToken)}>
        Search
      </Button>
    </Box>


    <Box
  sx={{
    display: "flex",
    flexDirection: "column",
    gap: 2,
    // border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    width: { xs: "100%", sm: "100%", md: "100%", lg: "180%", xl: "180%" },
    margin: "auto", // Centers the Box horizontally
    padding: "5%",
    maxWidth: "1200px",
  }}
>
      <Box sx={{ display: 'flex', justifyContent: 'left' }}>
  <Typography variant="h4">Patient Details</Typography>
</Box><br/>  <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField id="filled-basic" label="Full Name" variant="outlined" error={!!errors.name} helperText={errors.name?.message} {...field} autoComplete='off' />
            )}
          />
          <Controller
            name="age"
            control={control}
            render={({ field }) => (
              <TextField id="filled-basic" label="Age" variant="outlined" error={!!errors.age} helperText={errors.age?.message} {...field} autoComplete='off' />
            )}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field }) => ( 
              <TextField id="filled-basic" label="Phone Number" variant="outlined" error={!!errors.phone} helperText={errors.phone?.message} {...field} autoComplete='off' />
            )}
          />
          <FormControl fullWidth error={!!errors.gender}>
            <InputLabel htmlFor="select-gender">Gender</InputLabel>
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <Select id="select-gender" label="Gender" {...field}>
                  {gender.map(item => (
                    <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.gender && <Typography color="error">{errors.gender.message}</Typography>}
          </FormControl>
          {customRegistersData?.map((register: any, index: any) => (
            <TextField key={index} id={register.name} label={register.name} onChange={onChangeHandler} value={dynamicFieldValue[register.name] || ''} autoComplete='off' />
          ))}
          </Box>
          <br/><br/>
          <Box
  sx={{
    display: "flex",
    flexDirection: "column",
    gap: 2,
    // border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    width: { xs: "100%", sm: "100%", md: "100%", lg: "180%", xl: "180%" },
    margin: "auto", // Centers the Box horizontally
    padding: "5%",
    
  }}
>
                <Box sx={{ display: 'flex', justifyContent: 'left' }}>
         <Typography variant="h4">Doctor Details</Typography>
          </Box><br/> 
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" spacing={3}>
          <FormControl fullWidth error={!!errors.department}>
            <InputLabel htmlFor="select-department">Departments</InputLabel>
            <Controller
              name="department"
              control={control}
              render={({ field }) => (
                <Select id="select-department" label="Departments" {...field} onChange={handleDepartmentChange}>
                  {user?.department?.map(item => (
                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.department && <Typography color="error">{errors.department.message}</Typography>}
          </FormControl>
          <FormControl fullWidth error={!!errors.doctor}>
            <InputLabel htmlFor="select-doctor">Select Doctor</InputLabel>
            <Controller
              name="doctor"
              control={control}
              render={({ field }) => (
                <Select id="select-doctor" label="Select Doctor" {...field} onChange={handleDoctorChange}>
                  {filterDoctor?.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      <Stack width="100%" direction='row' justifyContent='space-between' alignItems='center'>
                        <Box>
                          <Typography variant='body1'><b>{item.name}</b></Typography>
                          <Typography variant='body1'>{item?.department}</Typography>
                        </Box>
                        <Typography variant='body1'>Room No: <b>{item?.roomNo}</b></Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.doctor && <Typography color="error">{errors.doctor.message}</Typography>}
          </FormControl>
          {selectedDoctor[0]?.fees && <TextField id="filled-basic" label="Doctor Fees" value={selectedDoctor[0]?.fees || 'Fees'}
            InputProps={{
              readOnly: true,
            }} />}
          {selectedDoctor[0]?.roomNo &&
            <TextField id="filled-basic" label="Room No" value={selectedDoctor[0]?.roomNo}
              InputProps={{
                readOnly: true,
              }} />}

          <FormControl fullWidth error={!!errors.date}>
            <InputLabel htmlFor="select-date">Date</InputLabel>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <Select id="select-date" label="Date" {...field} onChange={handleDateChange}>
                  {viewDoctorScheduleData?.map((item: any) => (
                    <MenuItem key={item?.id} value={item?.date_string}>{item.date_string}</MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.date && <Typography color="error">{errors.date.message}</Typography>}
          </FormControl>

          <FormControl fullWidth error={!!errors.slotId}>
            <InputLabel htmlFor="select-slot">Slot Availability</InputLabel>
            <Controller
              name="slotId"
              control={control}
              render={({ field }) => (
                <Select id="select-slotId" label="Slot Availability" {...field}>
                  {viewDoctorScheduleData
                    ?.find((schedule: any) => schedule.date_string === watchedDate)
                    ?.slotAvailability?.map((item: any) => (
                      <MenuItem key={item?.id} value={item?.id}>{item?.startTime} - {item?.endTime}</MenuItem>
                    ))}
                </Select>
              )}
            />
            {errors.slotId && <Typography color="error">{errors.slotId.message}</Typography>}
          </FormControl>
        
          
         
          <Stack direction="row" justifyContent='space-between'>
            <Button variant="contained" color="success" type="submit" disabled={loading}>
              {loading ? <CircularProgress size={25} /> : 'Submit'}
            </Button>
            <Button variant="contained" color="warning" onClick={handleClearForm}>Clear Form</Button>
          </Stack>
          
        </Stack>
      </form> </Box>
    </Stack>
   
  );
};

export default PatientRegistrationLayout;

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const CustomToast = ({
  registeredData,
  user,
  registeredPatientResponse,
  selectedDoctor,
  setSelectedDoctor,
}: any) => {
  const [openPrint, setOpenPrint] = useState(false);
  const componentRef = React.useRef<HTMLDivElement | null>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const printToast = () => {
    setOpenPrint(true);
  };

  const onPrintHandler = () => {
    handlePrint();
  };

  console.log({
    registeredData,
    user,
    registeredPatientResponse,
    selectedDoctor,
    setSelectedDoctor,
  });

  React.useEffect(() => {
    const styleTag = document.createElement("style");
    styleTag.innerHTML = `
      @media print {
        @page {
          size: 70mm 80mm;
        }
        html, body {
            height: 100vh; /* Use 100% here to support printing more than a single page*/
            margin: 0 !important;
            padding: 0 !important;
            overflow: hidden;
          }
      }
    `;
    document.head.appendChild(styleTag);
    return () => {
      document.head.removeChild(styleTag);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box style={{ display: "flex", justifyContent: "space-around" }}>
        <Typography variant="body2">Token Generated Successfully</Typography>
        <i className="material-icons" onClick={printToast}>
          <LocalPrintshopIcon />
        </i>

        <Modal
          open={openPrint}
          onClose={() => setOpenPrint(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} style={{ width: 400 }}>
            <Stack
              spacing={1}
              style={{ textAlign: "center" }}
              ref={componentRef}
            >
              <Typography variant="h6">{registeredData?.name || ""}</Typography>
              <Typography variant="h6">
                Token No : {registeredPatientResponse?.tokenNumber || ""}
              </Typography>
              <Typography variant="h4">
                {selectedDoctor[0]?.department || ""}
              </Typography>
              <Typography variant="h6">
                {`Date : ` +
                  new Date(
                    registeredPatientResponse?.createdAt
                  ).toLocaleDateString("en-us") || ""}
              </Typography>
              <Typography variant="h6">{`${
                registeredData?.name
              }, wishing you a speedy recovery at ${
                user?.userName || "our hospital"
              }`}</Typography>
              <br />
            </Stack>
            <Stack
              direction={"row"}
              spacing={2}
              style={{ justifyContent: "end" }}
            >
              <Button variant="contained" onClick={onPrintHandler}>
                Print
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "white", color: "black" }}
                onClick={() => {
                  setOpenPrint(false);
                  setSelectedDoctor({});
                }}
              >
                Close
              </Button>
            </Stack>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
};
