import React from 'react';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { Images } from '../assets/images';
import LoginTab from '../components/LoginTab';

const LandingLayout = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:960px)');

    return (
        <Grid 
            container 
            spacing={isMobile ? 2 : 4} 
            sx={{ padding: isMobile ? '20px' : isTablet ? '40px' : '60px', alignItems: 'center' }}
        >
            <Grid 
                item 
                xs={12} 
                sm={12} 
                md={6} 
                lg={6} 
                sx={{ textAlign: isMobile ? 'center' : 'left' }}
            >
                <Stack direction="column" spacing={3} alignItems={isMobile ? 'center' : 'flex-start'}>
                    <img 
                        alt='Logo' 
                        src={Images.logoLight} 
                        style={{ maxHeight: '60px', maxWidth: '150px' }} 
                    />
                    <Typography 
                        lineHeight={1.5} 
                        variant={isMobile ? 'h5' : isTablet ? 'h4' : 'h3'}
                    >
                        Welcome to etiicos <br />The Smart Patient Care System 
                    </Typography>
                    <LoginTab />
                </Stack>
            </Grid>
            <Grid 
                item 
                xs={12} 
                sm={12} 
                md={6} 
                lg={6} 
                sx={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-end' }}
            >
                <img 
                    alt='Banner' 
                    src={Images.banner} 
                    style={{ maxHeight: isMobile ? '40vh' : isTablet ? '50vh' : '60vh', width: '100%', maxWidth: '500px' }} 
                />
            </Grid>
        </Grid>
    );
};

export default LandingLayout;