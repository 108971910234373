import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import DataGridContainer from "../../components/DataGrid";
import Clock from "../../components/Clock";
import { useToken } from "../../hooks/useToken";
import { useUserAuth } from "../../provider/AuthProvider";
import { Box, Slider, IconButton } from "@mui/material";
import { ZoomIn, ZoomOut } from "@mui/icons-material";
import { useSocketContext } from "../../provider/SocketProvider";

const TokenSystemHomePage = () => {
  const { getTokenSystemList, tokenSystemList } = useToken();
  const { user } = useUserAuth();
  const { connectionStatus, lastMessage } = useSocketContext();

  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    getTokenSystemList();
  }, []);

  // Define DataGrid columns
  const columns: GridColDef[] = [
    { field: "tokenNo", headerName: "Token No", flex: 1, headerAlign: "center", minWidth: 120 },
    { field: "name", headerName: "Name", flex: 1, headerAlign: "center", minWidth: 150 },
    { field: "patientId", headerName: "Patient ID", flex: 1, headerAlign: "center", minWidth: 130 },
    { field: "department", headerName: "Department", flex: 1, headerAlign: "center", minWidth: 150 },
    { field: "doctorName", headerName: "Doctor Name", flex: 1, headerAlign: "center", minWidth: 170 },
    { field: "roomNo", headerName: "Room No", flex: 1, headerAlign: "center", minWidth: 100 },
  ];

  const handleZoomChange = (event: Event, newValue: number | number[]) => {
    setZoom(newValue as number);
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, 2));
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.5));
  };

  return (
    <Box sx={{ padding: 2, display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
      {/* Left Section - Clock & DataGrid */}
      <Box sx={{ flex: 1, minWidth: 300 }}>
        <Clock />
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <IconButton onClick={handleZoomIn} sx={{ mx: 1 }}>
            <ZoomIn />
          </IconButton>
          <Slider
            value={zoom}
            onChange={handleZoomChange}
            min={0.5}
            max={2}
            step={0.1}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => `${Math.round(value * 100)}%`}
            sx={{ flexGrow: 1, mx: 2 }}
          />
          <IconButton onClick={handleZoomOut} sx={{ mx: 1 }}>
            <ZoomOut />
          </IconButton>
        </Box>

        {/* DataGrid Container - Fixed Size & Zoom Inside Table */}
        <Box sx={{ width: "100%", height: "400px", overflow: "hidden", position: "relative" }}>
          <Box
            sx={{
              transform: `scale(${zoom})`,
              transformOrigin: "top left",
              width: `calc(100% / ${zoom})`, // Adjust width to prevent horizontal scrolling
              height: `calc(100% / ${zoom})`, // Adjust height accordingly
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DataGridContainer
              columns={columns}
              rows={tokenSystemList}
              autoHeight={false}
              sx={{
                height: "100%",
                width: "100%", // Ensure the table fills the container
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#f5f5f5",
                  fontWeight: "bold",
                  fontSize: "1rem",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  textAlign: "center",
                },
                "& .MuiDataGrid-cell": {
                  fontSize: "1rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
                "& .MuiDataGrid-row": {
                  height: "50px",
                },
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* Right Section - Embedded YouTube Videos */}
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
        <iframe
          width="100%"
          height="250"
          src="https://www.youtube.com/embed/dQw4w9WgXcQ"
          title="YouTube video"
          frameBorder="0"
          allowFullScreen
        ></iframe>
        <iframe
          width="100%"
          height="250"
          src="https://www.youtube.com/embed/dQw4w9WgXcQ"
          title="YouTube video"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </Box>
    </Box>
  );
};

export default TokenSystemHomePage;
