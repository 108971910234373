import React from 'react';
import AdminHardwareLayout from '../../layout/admin/AdminHardwareLayout';

const AdminHardware = () => {
  return (
    <AdminHardwareLayout />
  );
};

export default AdminHardware;
