import React from "react";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import DataGridContainer from "../../components/DataGrid";
import BreadCrumbs from "../../components/BreadCrumbs";
import { AppointmentDetails } from "../../hooks/useToken";
import { useUserAuth } from "../../provider/AuthProvider";
import { useSocketContext } from "../../provider/SocketProvider";
import { isValidJSON } from "../../utils/checkJson";

const columns: GridColDef[] = [
  {
    field: "patientId",
    headerName: "Patient ID",
    flex: 1,
    minWidth: 90,
    renderCell: (row) => <>{String(row?.row?.patientId?.patientId)}</>,
  },
  {
    field: "name",
    headerName: "Patient Name",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "doctorname",
    headerName: "Doctor Name",
    flex: 1,
    minWidth: 100,
    renderCell: (row) => <>{String(row?.row?.doctorId?.name)}</>,
  },
  {
    field: "tokenNo",
    headerName: "Token No",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "roomNo",
    headerName: "Room No",
    flex: 1,
    minWidth: 90,
  },
  {
    field: "department",
    headerName: "Department",
    flex: 1,
    minWidth: 90,
  },
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    minWidth: 90,
    renderCell: (row) => {
      let convertedDate: any = new Date(row.row.date);
      return <>{convertedDate.toLocaleDateString("en-us")}</>;
    },
  },
  {
    field: "startTime",
    headerName: "Start Time",
    flex: 1,
    minWidth: 90,
    renderCell: (row) => {
      let startTime: any = new Date(row.row.startTime);
      return <>{startTime.toLocaleTimeString("en-us", { hour: "2-digit", minute: "2-digit" })}</>;
    },
  },
  {
    field: "endTime",
    headerName: "End Time",
    flex: 1,
    minWidth: 90,
    renderCell: (row) => {
      let endTime: any = new Date(row.row.endTime);
      return <>{endTime.toLocaleTimeString("en-us", { hour: "2-digit", minute: "2-digit" })}</>;
    },
  },
  {
    field: "duration",
    headerName: "Duration",
    flex: 1,
    minWidth: 90,
    renderCell: (row) => {
      let start = new Date(row.row.startTime);
      let end = new Date(row.row.endTime);
      let duration = Math.abs((end.getTime() - start.getTime()) / 1000);
      let hours = Math.floor(duration / 3600);
      let minutes = Math.floor((duration % 3600) / 60);
      let seconds = duration % 60;
      return <>{`${hours}H : ${minutes}M : ${seconds}S`}</>;
    },
  },
];

const TokenLogLayout = ({ tokenList }: { tokenList: AppointmentDetails[] }) => {
  const { user } = useUserAuth();
  const { lastMessage } = useSocketContext();

  React.useEffect(() => {
    if (lastMessage && isValidJSON(lastMessage.data)) {
      const message = JSON.parse(lastMessage.data);
      if (message.topic === "active_call" && user?.id === message.hospitalId && message.from === "doctor") {
        // Handle real-time updates
      }
    }
  }, [lastMessage, user]);

  return (
    <Box sx={{ width: "100%", overflowX: "auto" }}>
      <BreadCrumbs data={["Home", "Token", "Token Logs"]} title="Token Logs" />
      <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: "100%", maxWidth: "1200px" }}>
          <DataGridContainer columns={columns} rows={tokenList} />
        </Box>
      </Box>
    </Box>
  );
};

export default TokenLogLayout;
