import React, { useState, useEffect } from 'react';
import {
    Box, Button, Divider, FormControl, InputLabel, OutlinedInput,
    Stack, Typography, Select, MenuItem, IconButton, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, Paper, Modal
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useForm, Controller } from 'react-hook-form';
import { useHardware } from '../../hooks/useHardware';

interface Hardware {
    id?: string;
    macAddress: string;
    hospitalId: string | { _id: string; name: string };
    doctorId: string | { _id: string; name: string };
}

const AdminHardwareLayout = () => {
    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm<Hardware>({
        defaultValues: {
            macAddress: '',
            hospitalId: '',
            doctorId: ''
        }
    });

    const { hardwareList, hospitals, doctors, loading, error, fetchHardware } = useHardware();
    const [modalOpen, setModalOpen] = useState(false);
    const [editId, setEditId] = useState<string | null>(null);
    const [selectedHospital, setSelectedHospital] = useState<string>('');

    // Environment Variables
    const apiDeployUrl = process.env.REACT_APP_API_URL;

    // Submit form for creating/updating hardware
    const onSubmit = async (data: Hardware) => {
        const token = localStorage.getItem('authToken');
        if (!token) {
            console.error('Unauthorized. No token found.');
            return;
        }

        const hardwareData = {
            mac_address: data.macAddress,
            doctor_id: data.doctorId,
            hospital_id: data.hospitalId,
        };

        try {
            const url = editId
                ? `${apiDeployUrl}admin/hardware/${editId}`
                : `${apiDeployUrl}admin/hardware`;
            const method = editId ? 'PUT' : 'POST';

            const response = await fetch(url, {
                method,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(hardwareData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                alert(`Error: ${errorData.message || 'Failed to process request'}`);
                return;
            }

            const result = await response.json();
            if (result.success) {
                alert(result.message);
                reset();
                setModalOpen(false);
                setEditId(null);
                fetchHardware(); // Refresh hardware list
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Error submitting form');
        }
    };

    // Open edit form
    const openEditForm = (hardware: Hardware) => {
        setEditId(hardware.id || null);
        setValue("macAddress", hardware.macAddress);
        setValue("hospitalId", typeof hardware.hospitalId === 'object' ? hardware.hospitalId._id : hardware.hospitalId); // Ensure using the ID for hospital
        setValue("doctorId", typeof hardware.doctorId === 'object' ? hardware.doctorId._id : hardware.doctorId); // Ensure using the ID for doctor
        setSelectedHospital(typeof hardware.hospitalId === 'object' ? hardware.hospitalId._id : hardware.hospitalId); // Ensure the hospital ID is set
        setModalOpen(true);
    };

    // Delete hardware entry
    const deleteHardware = async (hardwareId: string) => {
        const token = localStorage.getItem('authToken');
        if (!token) {
            console.error('Unauthorized. No token found.');
            return;
        }

        try {
            const response = await fetch(`${apiDeployUrl}admin/hardware/${hardwareId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                alert(`Error: ${errorData.message || 'Failed to delete hardware'}`);
                return;
            }

            const result = await response.json();
            if (result.success) {
                alert(result.message);
                fetchHardware(); // Refresh hardware list after deletion
            }
        } catch (error) {
            console.error('Error deleting hardware:', error);
            alert('Error deleting hardware');
        }
    };

    return (
        <Box>
            <Typography variant='h4' gutterBottom>Manage Hardware</Typography>
            <Box marginTop="1.5rem">
                <Stack direction="row" justifyContent="space-between" marginBottom="1rem">
                    <Typography variant='body1'>Hardware List</Typography>
                    <Button 
                        variant='contained' 
                        startIcon={<AddCircleOutlineIcon />} 
                        onClick={() => {
                            reset();
                            setEditId(null);
                            setModalOpen(true);
                        }}>
                        Create Hardware
                    </Button>
                </Stack>
                <Divider />

                {/* Modal for Form */}
                <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                    <Box sx={{
                        position: 'absolute', top: '50%', left: '50%',
                        transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper',
                        boxShadow: 24, p: 4, borderRadius: 2
                    }}>
                        <Typography variant='h6'>{editId ? "Edit Hardware" : "Create Hardware"}</Typography>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Stack spacing={2} marginBottom={2} marginTop={2}>
                                <FormControl error={!!errors.macAddress}>
                                    <InputLabel htmlFor="mac-address">MAC Address</InputLabel>
                                    <Controller
                                        name="macAddress"
                                        control={control}
                                        rules={{ required: 'MAC Address is required' }}
                                        render={({ field }) => <OutlinedInput {...field} id="mac-address" label="MAC Address" disabled={!!editId} />}
                                    />
                                </FormControl>

                                <FormControl error={!!errors.hospitalId}>
                                    <InputLabel>Hospital</InputLabel>
                                    <Controller
                                        name="hospitalId"
                                        control={control}
                                        rules={{ required: 'Hospital is required' }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                label="Hospital"
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    field.onChange(selectedValue);
                                                    setSelectedHospital(typeof selectedValue === 'string' ? selectedValue : selectedValue._id);
                                                    setValue("doctorId", "");
                                                }}
                                            >
                                                {hospitals.map((hospital: any) => (
                                                    <MenuItem key={hospital._id} value={hospital._id}>{hospital.name}</MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormControl>

                                <FormControl error={!!errors.doctorId}>
                                    <InputLabel>Doctor</InputLabel>
                                    <Controller
                                        name="doctorId"
                                        control={control}
                                        rules={{ required: 'Doctor is required' }}
                                        render={({ field }) => (
                                            <Select {...field} label="Doctor">
                                                {doctors
                                                    .filter((doctor: any) => 
                                                        doctor.hospital.some((h: any) => h.id === selectedHospital)
                                                    )
                                                    .map((doctor: any) => (
                                                        <MenuItem key={doctor.id} value={doctor.id}>{doctor.name}</MenuItem>
                                                    ))}
                                            </Select>
                                        )}
                                    />
                                </FormControl>

                                <Stack direction="row" spacing={2}>
                                    <Button type="submit" variant='contained'>{editId ? "Update" : "Submit"}</Button>
                                    <Button variant='outlined' onClick={() => setModalOpen(false)}>Cancel</Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Box>
                </Modal>

                {/* Table Format */}
                <Box marginTop="2rem">
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>MAC Address</b></TableCell>
                                    <TableCell><b>Hospital</b></TableCell>
                                    <TableCell><b>Doctor</b></TableCell>
                                    <TableCell><b>Actions</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {hardwareList.map((hardware) => (
                                    <TableRow key={hardware.id}>
                                        <TableCell>{hardware.macAddress}</TableCell>
                                        <TableCell>{typeof hardware.hospitalId === 'object' ? hardware.hospitalId.name : hardware.hospitalId}</TableCell>
                                        <TableCell>{typeof hardware.doctorId === 'object' ? hardware.doctorId.name : hardware.doctorId}</TableCell>
                                        <TableCell>
                                            <IconButton color="primary" onClick={() => openEditForm(hardware)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton color="error" onClick={() => deleteHardware(hardware.id || '')}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    );
};

export default AdminHardwareLayout;
