import * as React from "react";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { Toolbar, useMediaQuery, useTheme } from "@mui/material";

const AppLayout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // ✅ Get isMobile value

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar /> {/* ✅ Pass isMobile to Sidebar */}
      <Box component="main" sx={{  ml: isMobile ? 0 : "80px" }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default AppLayout;