import React, { useCallback, useContext } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import BreadCrumbs from "../../components/BreadCrumbs";
import ActiveTokenContainer from "../../components/ActiveTokenContainer";
import DataGridContainer from "../../components/DataGrid";
import { TokenCountResponse, TokenResponse } from "../../hooks/useToken";
import { useTokenCall } from "../../hooks/useTokenCall";
import Modal from "@mui/material/Modal";
import { useUserAuth } from "../../provider/AuthProvider";
import { useSocketContext } from "../../provider/SocketProvider";
import { useReactToPrint } from "react-to-print";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "85vh",
  overflow: "auto",
};

interface ActiveTokenLayoutProps {
  rows: TokenResponse[];
  tokenCount: TokenCountResponse[];
  selectedToken: TokenCountResponse | null;
  onClickDepartment: (item: TokenCountResponse) => void;
  onClickBack: () => void;
  refetch: () => Promise<void>;
}

const ActiveTokenLayout = ({
  rows,
  tokenCount,
  selectedToken,
  onClickBack,
  onClickDepartment,
  refetch,
}: ActiveTokenLayoutProps) => {
  const theme = useTheme();
  const { startCall, endCall } = useTokenCall();
  const [openInfo, setOpenInfo] = React.useState(false);
  const [info, setInfo] = React.useState({
    name: "",
    roomNo: "",
    startTime: "",
    endTime: "",
    id: "",
    tokenId: "",
    tokenNo: 0,
    department: "",
    date: "",
    slot: {
      start_time: "",
      end_time: "",
    },
    patientId: {
      age: 0,
      phone: "",
      gender: "",
    },
    meta: {} as Record<string, string>,
  });
  const [printInfo, setPrintInfo] = React.useState(false);
  const { user } = useUserAuth();
  const { sendMessage } = useSocketContext();

  const componentRef = React.useRef<HTMLDivElement | null>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onPrintHandler = () => {
    handlePrint();
  };

  React.useEffect(() => {
    const styleTag = document.createElement("style");
    styleTag.innerHTML = `
      @media print {
        @page {
          size: 70mm 80mm;
        }
        html, body {
            height: 100vh; /* Use 100% here to support printing more than a single page*/
            margin: 0 !important;
            padding: 0 !important;
            overflow: hidden;
          }
      }
    `;
    document.head.appendChild(styleTag);
    return () => {
      document.head.removeChild(styleTag);
    };
  }, []);

  const onClickCall = useCallback(
    async (id: string, isActive: string) => {
      console.log();
      if (isActive) {
        sendMessage(
          JSON.stringify({ topic: "token_call", tokenId: id, action: "end" })
        );
        await endCall(id);
      } else {
        sendMessage(
          JSON.stringify({
            topic: "token_call",
            tokenId: id,
            action: "start",
          })
        );
        await startCall(id);
      }
      await refetch();
    },
    [startCall, refetch, endCall]
  );

  const onInfoClickHandler = (data: {
    name: string;
    roomNo: string;
    startTime: string;
    endTime: string;
    id: string;
    tokenId: string;
    tokenNo: number;
    department: string;
    date: string;
    patientId: {
      age: number;
      phone: string;
      gender: string;
    };
    slot: { start_time: string; end_time: string };
    meta: Record<string, string>;
  }) => {
    setInfo(data);
    setOpenInfo(true);
  };

  const onPrintClickHandler = (data: {
    name: string;
    roomNo: string;
    startTime: string;
    endTime: string;
    id: string;
    tokenId: string;
    tokenNo: number;
    department: string;
    date: string;
    patientId: {
      age: number;
      phone: string;
      gender: string;
    };
    slot: { start_time: string; end_time: string };
    meta: Record<string, string>;
  }) => {
    setInfo(data);
    setPrintInfo(true);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 2,
      //Reduce "ID" column to 8 bit.
      renderCell: (row) => {
        return <>{String(row?.row?.id).slice(0, 4)}</>;
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 2,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 2,
      //"Date" column to be in 24 hours format
      renderCell: (row) => {
        let dateTobeChanged = new Date(row.row.date);
        let twentyFourHourFormat = dateTobeChanged.toLocaleDateString("en-US", {
          hour: undefined,
          minute: undefined,
          hour12: false,
        });
        return <>{twentyFourHourFormat}</>;
      },
    },
    {
      field: "tokenNo",
      headerName: "Token No",
      flex: 2,
    },
    {
      field: "createdBy",
      headerName: "Created Using",
      flex: 2,
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 2,
      renderCell: (row) => {
        return (
          <Stack direction="row" alignItems="center" spacing={2}>
            {row.row.startTime && row.row.endTime ? (
              <Button></Button>
            ) : (
              <Button
                onClick={() =>
                  onClickCall(row?.row?.tokenId, row?.row?.startTime)
                }
                variant="contained"
                size="small"
                style={{ backgroundColor: row?.row?.startTime ? "red" : "" }}
              >
                {row?.row?.startTime ? "End" : "Call"}
              </Button>
            )}
            <IconButton onClick={() => onInfoClickHandler(row?.row)}>
              <InfoOutlinedIcon />
            </IconButton>
            <IconButton onClick={() => onPrintClickHandler(row?.row)}>
              <LocalPrintshopIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];
  return (
    <Box>
      <BreadCrumbs
        data={["Home", "Token", "Active Tokens"]}
        title="Active Tokens"
      />
      <Typography sx={{ marginTop: "24px", marginBottom: "24px" }} variant="h4">
        {selectedToken ? (
          <IconButton
            sx={{ color: theme?.palette?.text?.primary }}
            onClick={onClickBack}
          >
            <ArrowBackIcon />
            <span style={{ marginLeft: "16px" }}>{selectedToken.name}</span>
          </IconButton>
        ) : (
          "Department Wise List"
        )}
      </Typography>
      {selectedToken ? (
        <Stack>
       <Box sx={{ width: "100%", overflowX: "auto" }}>
  <Box
    sx={{
      minWidth: "1100px", // Ensures width remains large on laptops
      "@media (max-width: 1024px)": {
        minWidth: "1100px", // Keeps width fixed on mobile & tablets
        overflowX: "auto",  // Enables scrolling instead of responsiveness
      },
    }}
  >
    <DataGridContainer columns={columns} rows={rows} />
  </Box>
</Box>


          <Modal
            open={openInfo}
            onClose={() => setOpenInfo(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h4" component="h2">
                Token Information
              </Typography>
              <br />
              <Stack spacing={2}>
                <TextField
                  id="filled-basic"
                  label="Name"
                  defaultValue={info?.name}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label="Age"
                  defaultValue={info?.patientId?.age}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label="Phone"
                  defaultValue={info?.patientId?.phone}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label="Gender"
                  defaultValue={info?.patientId?.gender}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  id="filled-basic"
                  label="Token Id"
                  defaultValue={info?.tokenId.slice(0, 4)}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  id="filled-basic"
                  label="Token No"
                  defaultValue={info?.tokenNo}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label="Department"
                  defaultValue={info?.department}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label="Room No."
                  defaultValue={info?.roomNo}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label="Slot"
                  defaultValue={`${info?.slot?.start_time} - ${info?.slot?.end_time}`}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                {
                  info?.meta &&
                    Object.keys(info?.meta).map((key) => (
                      <TextField
                        key={key}
                        label={key}
                        defaultValue={info?.meta[key]}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    ))
                }
              </Stack>
              <Stack
                direction={"row"}
                style={{ justifyContent: "end", marginTop: "12px" }}
              >
                <Button variant="contained" onClick={() => setOpenInfo(false)}>
                  Close
                </Button>
              </Stack>
            </Box>
          </Modal>

          <Modal
            open={printInfo}
            onClose={() => setPrintInfo(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} style={{ width: 600, height: "auto" }}>
              <Stack
                spacing={1}
                style={{ textAlign: "center" }}
                ref={componentRef}
              >
                <Typography variant="h6">{info?.name || ""}</Typography>
                <Typography variant="h6">
                  Token No : {info?.tokenNo || ""}
                </Typography>
                <Typography variant="h4">{info?.department || ""}</Typography>
                <Typography variant="h6">
                  {`Date : ` +
                    new Date(info?.date).toLocaleDateString("en-us") || ""}
                </Typography>
                <Typography variant="h6">{`${
                  info?.name
                }, wishing you a speedy recovery at ${
                  user?.userName || "our hospital"
                }`}</Typography>
                <br />
              </Stack>
              <Stack
                direction={"row"}
                spacing={2}
                style={{ justifyContent: "end" }}
              >
                <Button variant="contained" onClick={onPrintHandler}>
                  Print
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "white", color: "black" }}
                  onClick={() => setPrintInfo(false)}
                >
                  Close
                </Button>
              </Stack>
            </Box>
          </Modal>
        </Stack>
      ) : (
        <>
          {tokenCount?.map((item) => (
            <ActiveTokenContainer
              key={item.id}
              count={item.count}
              title={item.name}
              onClick={() => onClickDepartment(item)}
            />
          ))}
        </>
      )}
    </Box>
  );
};

export default ActiveTokenLayout;
