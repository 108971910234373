import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import BreadCrumbs from '../../components/BreadCrumbs';
import { useDoctor } from '../../hooks/useDoctor';
import { useUserAuth } from '../../provider/AuthProvider';

interface Field {
  id: string;
  name: string;
}

interface FormValues {
  fields: Field[];
}

const SettingsLayout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useUserAuth();
  const { callList, createCustomCall, getCustomCall, deleteCustomCallById } = useDoctor();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      fields: [{ id: '', name: '' }],
    },
  });
  const [fields, setFields] = useState<Field[]>([{ id: '', name: '' }]);
  const [loading, setLoading] = useState<number | null>(null);

  useEffect(() => {
    if (callList) {
      setFields(callList);
    }
  }, [callList]);

  useEffect(() => {
    if (user?.hospital) {
      getCustomCall(user?.activeHospital?.id || user?.hospital?.[0]?.id);
    }
  }, [user, getCustomCall]);

  const addField = () => {
    const newField = { id: '', name: '' };
    setFields([...fields, newField]);
    setValue('fields', [...fields, newField]);
  };

  const removeField = async (id: string) => {
    try {
      await deleteCustomCallById(id, user?.activeHospital?.id || user?.hospital?.[0]?.id);
      setFields(fields.filter((field) => field.id !== id));
    } catch (error) {
      console.error('Error deleting field:', error);
    }
  };

  const saveField = async (index: number) => {
    try {
      setLoading(index);
      const fieldToSave = watch(`fields.${index}`);
      await createCustomCall({
        hospitalId: user?.activeHospital?.id || user?.hospital?.[0]?.id,
        message: fieldToSave?.name,
      });
      setLoading(null);
    } catch (error) {
      console.error('Error saving field:', error);
      setLoading(null);
    }
  };

  return (
    <Stack direction="column" spacing={3} sx={{ p: isMobile ? 2 : 4 }}>
      <BreadCrumbs data={['Home', 'Doctor', 'Settings']} title="Settings" />
      <Stack direction={isMobile ? 'column' : 'row'} justifyContent="space-between" alignItems="center">
        <Typography variant="h5" sx={{ fontSize: isMobile ? '1.2rem' : '1.5rem' }}>
          Customize Content
        </Typography>
        <Button variant="contained" startIcon={<AddCircleOutlineIcon />} onClick={addField}>
          Add Tab
        </Button>
      </Stack>
      <form onSubmit={handleSubmit(() => saveField(-1))}>
        {fields.map((field: any, index) => (
          <Stack
            key={field.id || index}
            direction={isMobile ? 'column' : 'row'}
            spacing={2}
            alignItems={isMobile ? 'stretch' : 'center'}
            mb={2}
          >
            <FormControl fullWidth error={!!errors.fields?.[index]?.name}>
              <InputLabel htmlFor={`outlined-adornment-name${field.id || index}`}>Message</InputLabel>
              <Controller
                name={`fields.${index}.name`}
                control={control}
                defaultValue={field?.name || ''}
                rules={{ required: 'Field is required' }}
                render={({ field: controllerField }) => (
                  <OutlinedInput
                    {...controllerField}
                    id={`outlined-adornment-name${field.id || index}`}
                    label="Message"
                    value={controllerField.value || ''}
                    onChange={(e) => {
                      controllerField.onChange(e);
                      const updatedFields = [...fields];
                      updatedFields[index].name = e.target.value;
                      setFields(updatedFields);
                      setValue('fields', updatedFields);
                    }}
                  />
                )}
              />
              {errors.fields?.[index]?.name && (
                <Typography variant="caption" color="error">
                  {errors.fields[index]?.name?.message}
                </Typography>
              )}
            </FormControl>
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                startIcon={loading === index ? <CircularProgress size={12} /> : <SaveAltIcon />}
                onClick={(e) => {
                  e.preventDefault();
                  saveField(index);
                }}
              >
                Save
              </Button>
              <IconButton
                sx={{
                  background: theme.palette.primary.main,
                  borderRadius: '8px',
                  minWidth: '60px',
                }}
                onClick={() => removeField(field.id)}
              >
                <DeleteIcon sx={{ color: theme.palette.text.secondary }} />
              </IconButton>
            </Stack>
          </Stack>
        ))}
      </form>
    </Stack>
  );
};

export default SettingsLayout;
