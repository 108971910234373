import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { 
  Button, 
  FormControl, 
  InputLabel, 
  MenuItem, 
  Select, 
  Stack, 
  Typography, 
  useTheme, 
  useMediaQuery,
  IconButton,
  Menu,
  Box
} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { Images } from "../assets/images";
import { useNavigate } from "react-router-dom";
import { setUserSessionToken } from "../config/AxiosClient";
import { useUserAuth } from "../provider/AuthProvider";
import { Roles } from "../constants/Roles";
import { useDoctor } from "../hooks/useDoctor";

const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user, setUser } = useUserAuth();
  const { activeHospital } = useDoctor();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onClickLogout = React.useCallback(() => {
    localStorage.clear();
    setUserSessionToken(null);
    setUser(null);
    navigate('/auth');
  }, [navigate, setUser]);

  const copyToClipboard = (userid?: string) => {
    if (userid) {
      navigator.clipboard.writeText(userid)
        .then(() => {})
        .catch((err) => console.log(err));
    }
  };

  const onHospitalChangeHandler = async (e: any) => {
    await activeHospital(e.target.value);

    setUser((prev: any) => ({
      ...prev,
      activeHospital: {
        ...prev.activeHospital,
        id: e.target.value,
      },
    }));
  };

  return (
    <AppBar 
      position="fixed" 
      sx={{ 
        zIndex: theme.zIndex.drawer + 1, 
        px: 2, 
        boxShadow: 'none', 
        borderBottom: `1px solid ${theme.palette.divider}`, 
        background: theme.palette.background.default 
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between", display: "flex" }}>
        
        {/* Left Section: Logo */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img style={{ maxHeight: '40px' }} alt="logo" src={Images.logoLight} />
        </Box>

        {isMobile ? (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton color="inherit" onClick={handleMenuOpen}>
                <MenuIcon />
              </IconButton>
              <Button onClick={onClickLogout} variant="contained" endIcon={<LogoutIcon />}>Logout</Button>
            </Box>
            <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
              {user?.role === Roles.hospital && (
                <MenuItem onClick={() => copyToClipboard(user.id)}>
                  <ContentCopyIcon sx={{ marginRight: 1 }} />
                  {user?.userName.length >= 1 ? user?.userName : user?.id}
                </MenuItem>
              )}
              {user?.role === Roles.doctor && (
                <MenuItem>
                  <FormControl fullWidth>
                    <InputLabel>Select Hospital</InputLabel>
                    <Select onChange={onHospitalChangeHandler}>
                      {user?.hospital?.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MenuItem>
              )}
            </Menu>
          </>
        ) : (
          <Stack direction="row" spacing={2} alignItems="center">
            {user?.role === Roles.hospital && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body1">Hospital Name:</Typography>
                <Button variant="contained" endIcon={<ContentCopyIcon />} onClick={() => copyToClipboard(user.id)}>
                  {user?.userName.length >= 1 ? user?.userName : user?.id}
                </Button>
              </Stack>
            )}

            {user?.role === Roles.doctor && (
              <FormControl sx={{ width: '200px' }}>
                <InputLabel>Select Hospital</InputLabel>
                <Select onChange={onHospitalChangeHandler}>
                  {user?.hospital?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <Button onClick={onClickLogout} variant="contained" endIcon={<LogoutIcon />}>
              Logout
            </Button>
          </Stack>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;