import React, { useCallback, useMemo, useState } from "react";
import {
  Drawer,
  List,
  CssBaseline,
  Box,
  Typography,
  Toolbar,
  Stack,
  IconButton,
  useMediaQuery
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import EventNoteIcon from "@mui/icons-material/EventNote";
import PaymentIcon from "@mui/icons-material/Payment";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import TokenIcon from "@mui/icons-material/Token";
import HardwareIcon from "@mui/icons-material/Hardware";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import { To, useLocation, useNavigate } from "react-router-dom";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import Header from "./Header";
import { useUserAuth } from "../provider/AuthProvider";
import { Roles } from "../constants/Roles";
import { useTheme } from "@mui/material/styles";
import styles from "./Sidebar.module.css";

export const drawerWidth = 240;

const Sidebar = () => {
  const theme = useTheme();
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerMenu = useMemo(() => {
    if (user?.role === Roles.doctor) {
      return [
        { name: "Profile", route: "/doctor/profile", icon: <PermContactCalendarOutlinedIcon /> },
        { name: "Doctor", route: "/doctor/dashboard", icon: <LocalHospitalIcon /> },
        { name: "Log", route: "/doctor/logs", icon: <EventNoteIcon /> },
        { name: "Settings", route: "/doctor/settings", icon: <SettingsOutlinedIcon /> },
      ];
    }
    if (user?.role === Roles.admin) {
      return [
        { name: "Dashboard", route: "/admin/dashboard", icon: <PermContactCalendarOutlinedIcon /> },
        { name: "Doctor", route: "/admin/doctor", icon: <LocalHospitalIcon /> },
        { name: "Hospital", route: "/admin/hospital", icon: <EventNoteIcon /> },
        { name: "Settings", route: "/admin/settings", icon: <SettingsOutlinedIcon /> },
        { name: "Hardware", route: "/admin/hardware", icon: <HardwareIcon /> },
        { name: "Zoom", route: "/admin/Zoom", icon: <ZoomInIcon /> },
      ];
    }
    if (user?.role === Roles.hospital) {
      return [
        { name: "Patient Registration", route: "/token/new", icon: <AppRegistrationIcon /> },
        { name: "Modify Rooms", route: "/token/rooms", icon: <MeetingRoomIcon /> },
        { name: "Departments", route: "/token/departments", icon: <AutoAwesomeMosaicIcon /> },
        { name: "Doctor Schedule", route: "/token/doctorschedule", icon: <LocalHospitalIcon /> },
        { name: "Active Tokens", route: "/token/active", icon: <TokenIcon /> },
        { name: "Token Log", route: "/token/logs", icon: <EventNoteIcon /> },
        { name: "Active Call", route: "/token/active-calls", icon: <PhoneEnabledIcon /> },
        { name: "Call Log", route: "/token/call-logs", icon: <LibraryBooksIcon /> },
        { name: "Settings", route: "/token/settings", icon: <SettingsOutlinedIcon /> },
        { name: "Payment", route: "/token/payment", icon: <PaymentIcon /> },
      ];
    }
    return [];
  }, [user]);

  const onClickNavigate = useCallback(
    (route: To) => {
      navigate(route);
      if (isMobile) setMobileOpen(false);
    },
    [navigate, isMobile]
  );

  const drawer = (
    <Box sx={{ width: drawerWidth }} role="presentation">
      <Toolbar />
      <List className={styles.listPadding}>
        {drawerMenu.map((menu, index) => (
          <Stack
            key={index}
            direction="row"
            spacing={1}
            onClick={() => onClickNavigate(menu.route)}
            className={styles.menuItem}
            sx={{
              color: location.pathname.toLowerCase() === menu.route.toLowerCase() ? theme.palette.text.secondary : theme.palette.text.primary,
              background: location.pathname.toLowerCase() === menu.route.toLowerCase() ? theme.palette.primary.main : theme.palette.background.default,
            }}
          >
            {menu.icon}
            <Typography>{menu.name}</Typography>
          </Stack>
        ))}
      </List>
    </Box>
  );

  return (
    <Box className={styles.sidebarContainer}>
      <Header /><CssBaseline />
      {isMobile || isTablet ? (
        <>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle} sx={{ position: "absolute", top: 16, left: 16, zIndex: 1300 }}>
            <MenuIcon />
          </IconButton>
          <Drawer anchor="left" open={mobileOpen} onClose={handleDrawerToggle} sx={{ "& .MuiDrawer-paper": { width: drawerWidth } }}>
            {drawer}
          </Drawer>
        </>
      ) : (
        <Drawer variant="permanent" className={styles.drawer} sx={{ "& .MuiDrawer-paper": { width: drawerWidth } }}>
          {drawer}
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
