import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, DataGridProps, GridColDef } from '@mui/x-data-grid';

interface DataGridContainerProps extends DataGridProps {
    columns: GridColDef[];
}

const DataGridContainer = ({ columns, ...gridProps }: DataGridContainerProps) => {
    return (
        <Box sx={{ width: '100%' }}> {/* Ensure full width without horizontal scrolling */}
            <DataGrid
                {...gridProps}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[10, 25, 50]}
                autoHeight
                pagination
                localeText={{ noRowsLabel: "No data available" }}
                sx={{
                    width: '100%',
                    overflow: 'hidden', // Prevent horizontal scrolling
                    '& .MuiDataGrid-columnHeaders': {
                        whiteSpace: 'normal', // Ensure all column headers are visible
                        wordBreak: 'break-word',
                    },
                    '& .MuiDataGrid-root': {
                        minWidth: '100%', // Ensure table takes full available space
                    },
                    '& .MuiDataGrid-cell': {
                        padding: '2px', // Further reduce cell padding to minimize column spacing
                    },
                    '& .MuiDataGrid-columnHeader': {
                        padding: '2px', // Further reduce header padding to match cell spacing
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        overflowX: 'hidden', // Ensure table does not scroll horizontally
                    },
                    '@media (max-width: 768px)': {
                        '& .MuiDataGrid-columnHeaders': {
                            fontSize: '12px', // Reduce font size for smaller screens
                        },
                        '& .MuiDataGrid-cell': {
                            fontSize: '12px', // Reduce cell font size for better fit
                        },
                    }
                }}
            />
        </Box>
    );
}

export default DataGridContainer;