import React, { useMemo } from 'react';
import Box from "@mui/material/Box";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface BreadCrumbsProps {
  data: string[];
  title: string;
}

const BreadCrumbs = ({ data, title }: BreadCrumbsProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const breadcrumbs = useMemo(() => {
    return data.map((item, index) => (
      <Typography key={index} variant={isMobile ? "body2" : "body1"}>
        {item}
      </Typography>
    ));
  }, [data, isMobile]);

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant={isMobile ? 'h5' : 'h2'} fontWeight={600} gutterBottom>
        {title}
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" sx={{ color: theme.palette.text.primary }} />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Divider sx={{ marginTop: 2 }} />
    </Box>
  );
}

export default BreadCrumbs;