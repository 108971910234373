import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  useTheme,
  Select,
  MenuItem,
  SelectChangeEvent,
  useMediaQuery,
  Box,
  Grid,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import BreadCrumbs from "../../components/BreadCrumbs";
import { useUserAuth } from "../../provider/AuthProvider";
import { useSettings } from "../../hooks/useSettings";
import { useSocketContext } from "../../provider/SocketProvider";
import { isValidJSON } from "../../utils/checkJson";
import { getHttpClient } from "../../config/AxiosClient";

interface Field {
  id: string;
  name: string;
}

interface FormValues {
  fields: Field[];
}

// const languages = ['ENGLISH','TAMIL','MARATHI','TELUGU','HINDI'];

const SettingsLayout = () => {
  const theme = useTheme();
  const { user } = useUserAuth();
  const {
    customRegisters,
    deleteCustomRegisters,
    settingsData,
    getCustomRegisters,
  } = useSettings();
  const { sendMessage, lastMessage } = useSocketContext();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      fields: [{ id: "", name: "" }],
    },
  });

  const [fields, setFields] = useState<Field[]>([{ id: "", name: "" }]);
  const [loading, setLoading] = useState<number | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState("english");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screen

  useEffect(() => {
    if (lastMessage && isValidJSON(lastMessage.data)) {
      const message = JSON.parse(lastMessage.data);
      if (
        message.topic === "active_call" &&
        user?.id === message.hospitalId &&
        message.from === "doctor"
      ) {
        getCustomRegisters();
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    getCustomRegisters();
  }, []);

  useEffect(() => {
    if (settingsData) {
      setFields(settingsData);
    }
  }, [settingsData]);

  useEffect(() => {
    const fetchLanguage = async () => { 
      try {
        const response = await getHttpClient("/auth/hospital/me", "GET");
        if (response?.hospital?.audio_language) {
          setSelectedLanguage(response.hospital.audio_language);
        } else {
          console.warn("Audio language not found, setting default.");
          setSelectedLanguage("ENGLISH"); // Default value if not found
        }
      } catch (error) {
        console.error("Error fetching audio language:", error);
      }
    };

    fetchLanguage(); 
  }, []);

  const addField = () => {
    const newField = { id: "", name: "" };
    setFields([...fields, newField]);
    setValue("fields", [...fields, newField]);
  };

  const removeField = async (selectedField: Field) => {
    try {
      await deleteCustomRegisters(selectedField.id);
      setFields(fields.filter((f) => f.id !== selectedField.id));
    } catch (error) {
      console.error("Error deleting field:", error);
    }
  };

  const saveField = async (index: number) => {
    try {
      setLoading(index);
      const fieldToSave = watch(`fields.${index}`);
      const payload = { label: fieldToSave.name };
      await customRegisters(payload);
      setLoading(null);
    } catch (error) {
      console.error("Error saving field:", error);
      setLoading(null);
    }
  };

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    const selectedLang = event.target.value;
    setSelectedLanguage(selectedLang);
    
    // Update audio_language state
    // const payload = JSON.stringify({
    //   type: "LANGUAGE_SELECTION",
    //   audio_language: selectedLang,
    // });
    // sendMessage(payload);
  };
  const handleLanguageSubmit = async () => {
    try {
      const payload = JSON.stringify({
        type: "LANGUAGE_SELECTION",
        language: selectedLanguage,
      });
      await getHttpClient('/hospital','PATCH',{language: selectedLanguage})
      // sendMessage(payload); // Send language selection to WebSocket
    } catch (error) {
      console.error("Error sending WebSocket message:", error);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <BreadCrumbs data={["Form", "Active calls", "Settings"]} title="Settings" />
      <Stack direction="column" spacing={3}>
        <Stack
          direction={isMobile ? "column" : "row"}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="h4" sx={{ textAlign: isMobile ? "center" : "left" }}>
            Patient Details Form
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={addField}
            sx={{ width: isMobile ? "100%" : "auto" }}
          >
            Add Tab
          </Button>
        </Stack>
        <form onSubmit={handleSubmit(() => saveField(-1))}>
          {fields.map((field, index) => (
            <Grid
              container
              key={field.id || index}
              spacing={2}
              alignItems="center"
              sx={{ mb: 2, flexWrap: "wrap" }}
            >
              <Grid item xs={12} sm={8} md={6} lg={5}>
                <FormControl fullWidth error={!!errors.fields?.[index]?.name}>
                  <InputLabel htmlFor={`outlined-adornment-name${index}`}>
                    Document type
                  </InputLabel>
                  <Controller
                    name={`fields.${index}.name`}
                    defaultValue={field?.name || ""}
                    control={control}
                    rules={{ required: "Field is required" }}
                    render={({ field: controllerField }) => (
                      <OutlinedInput
                        {...controllerField}
                        id={`outlined-adornment-name${index}`}
                        label="Document type"
                        value={controllerField.value || ""}
                        onChange={(e) => {
                          controllerField.onChange(e);
                          const updatedFields = [...fields];
                          updatedFields[index].name = e.target.value;
                          setFields(updatedFields);
                          setValue("fields", updatedFields);
                        }}
                      />
                    )}
                  />
                  {errors.fields?.[index]?.name && (
                    <Typography variant="caption" color="error">
                      {errors.fields[index]?.name?.message}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
    <Button
      fullWidth
      variant="contained"
      startIcon={loading === index ? <CircularProgress size={12} /> : <SaveAltIcon />}
      sx={{
        fontSize: { xs: "12px", sm: "14px", md: "16px" }, // Responsive font size
        padding: { xs: "8px", sm: "10px", md: "12px" }, // Responsive padding
      }}
      onClick={(e) => {
        e.preventDefault();
        saveField(index);
      }}
    >
      Save
    </Button>
  </Grid>

  {/* Delete Button (in same row) */}
  <Grid item xs={6} sm={3} md={2} display="flex" justifyContent="center">
    <IconButton
      sx={{
        background: theme.palette.primary.main,
        borderRadius: "8px",
        padding: { xs: "6px", sm: "8px", md: "10px" }, // Responsive padding
        width: "100%", // Ensures button fits grid size
      }}
      onClick={() => removeField(field)}
    >
      <DeleteIcon sx={{ color: theme.palette.text.secondary, fontSize: { xs: "18px", sm: "20px", md: "24px" } }} />
    </IconButton>
  </Grid>
</Grid>
          ))}
        </form>
        <Typography variant="h4">Audio Settings</Typography>
        <Grid container  alignItems="left">
          <Grid item xs={10} sm={8} md={6} lg={5}>
            <FormControl fullWidth>
              <InputLabel>Language</InputLabel>
              <Select value={selectedLanguage} onChange={handleLanguageChange}>
                <MenuItem value="ENGLISH">English</MenuItem>
                <MenuItem value="TAMIL">Tamil</MenuItem>
                <MenuItem value="MARATHI">Marathi</MenuItem>
                <MenuItem value="TELUGU">Telugu</MenuItem>
                <MenuItem value="HINDI">Hindi</MenuItem>
              </Select>
            </FormControl>
          </Grid>
         
          <Grid item xs={6} sm={2} sx={{ ml: 2 }}>
  <Button fullWidth variant="contained" onClick={handleLanguageSubmit}>
    Submit
  </Button>
</Grid>

        </Grid>
      </Stack>
    </Box>
  );
};

export default SettingsLayout;
