import React, { useCallback, useState, useEffect } from "react";
import { Box, IconButton, Typography, Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DataGridContainer from "../../components/DataGrid";
import BreadCrumbs from "../../components/BreadCrumbs";
import { ItemDetails } from "../../hooks/useDoctorList";
import { useUserAuth } from "../../provider/AuthProvider";
import ModifyRoomModal from "../../components/ModifyRoomModal";
import { useSocketContext } from "../../provider/SocketProvider";
import { isValidJSON } from "../../utils/checkJson";

const ModifyRoomLayout = ({
  doctorList,
  isLoading,
  saveRoom,
}: {
  doctorList: ItemDetails[];
  saveRoom: (id: string, room: number) => Promise<void>;
  isLoading: boolean;
}) => {
  const { user } = useUserAuth();
  const [selectedDetails, setSelectedDetails] = useState<ItemDetails | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { lastMessage } = useSocketContext();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onClickEdit = useCallback((data: ItemDetails) => {
    setSelectedDetails(data);
    setOpen(true);
  }, []);

  const columns: GridColDef[] = [
    { field: "department", headerName: "Department", flex: 1 },
    {
      field: "name",
      headerName: "Doctor Name",
      flex: 2,
      
    },
    {
      field: "roomNo",
      headerName: "Room No",
      flex: 2,
    },
    {
      field: "id",
      headerName: "Add/Edit Room",
      flex: 2,
      renderCell: (row) => (
        <IconButton onClick={() => onClickEdit(row.row)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    if (lastMessage && isValidJSON(lastMessage.data)) {
      const message = lastMessage.data;
      const msg = JSON.parse(message);
      if (
        msg.topic === "active_call" &&
        user?.id === msg.hospitalId &&
        msg.from === "doctor"
      ) {
        // Handle message event
      }
    }
  }, [lastMessage, user]);

  return (
    <Box sx={{ padding: { xs: 2, md: 4 } }}>
      <BreadCrumbs data={["Home", "Token", "Modify Rooms"]} title="Modify Rooms" />
      <Typography sx={{ marginTop: 2, marginBottom: 2 }} variant="h4">
        Rooms
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Box
  sx={{
    width: "100%",
    overflowX: "auto",
    display: "flex",
    justifyContent: "center",
  }}
>
<>
  <Box
    sx={{
      width: "100%",
      maxWidth: { xs: "100%", sm: "600px", md: "900px", lg: "1200px" },
      minWidth: { xs: "100%", sm: "600px", md: "800px" },
    }}
  >
              <DataGridContainer columns={columns} rows={doctorList} />
            </Box>
            </>
          </Box>
        </Grid>
      </Grid>
      <ModifyRoomModal
        isLoading={isLoading}
        saveRoom={saveRoom}
        open={open}
        handleClose={handleClose}
        selectedDetails={selectedDetails}
      />
    </Box>
  );
};

export default ModifyRoomLayout;