export const AuthEndPoints = {
    adminLogin: 'auth/admin/login',
    hospitalLogin: 'auth/hospital/login',
    doctorLogin: 'auth/doctor/login',
    tokenSystemLogin: 'auth/tokensystem/login',
    doctorRegister: 'auth/doctor/register',
    hospitalRegister: 'auth/hospital/register',
    verifyDoctorOTP: 'auth/doctor/verification',
    verifyHospitalOTP: 'auth/hospital/verification',
}

export const AdminEndpoints = {
    adminHospital: 'admin/hospitals',
    adminDoctor: 'admin/doctors',
    getCount: 'admin/dashboard'
}

export const OnBoardingUser = {
    registerHospital: 'auth/hospital',
    registerDoctor: 'auth/doctor',
    getAllHospital: 'hospital/all'
}

export const DoctorEndPoints = {
    getRoomById: 'room/',
    departments: 'departments',
    me: 'doctor',
    customCall: 'custom-calls',
    doctorList: 'doctors'
}

export const PaymentEndPoints = {
    payment:'payment/order',
    verify: 'payment/verify'
}

export const HospitalEndPoints = {
    departments: 'hospital/departments',
   
}

export const Logs = {
    callLogs: 'call-logs',
}

export const CustomCall = {
    call: 'custom-calls',
    activeCall: 'active-calls'
}

export const Fees = {
    fee: 'fees',
}

export const CurrentUser = {
    me: 'me'
}

export const Token = {
    token: 'token',
    tokenSystem: 'token/patients',
    activecount: 'token/activecount',
}

export const Room = {
    rooms: 'rooms'
}

export const DoctorSchedule = {
    doctorSchedule: 'doctor-schedule'
}

export const Settings = {
    customRegisters: 'custom-registers'
}
export const HardwareEndPoints = {
    addHardware: '/api/hardware/add',
    updateHardware: '/api/hardware/update',
    deleteHardware: '/api/hardware/delete',
    getHardwareById: '/api/hardware/:id',
    hardwareList: '/api/hardware',  
  };
  
