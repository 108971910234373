import React from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  FormHelperText,
  useMediaQuery,
  Box,
} from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { Images } from '../assets/images';

interface LoginLayoutProps {
  role: string;
  isLoading: boolean;
  form: UseFormReturn<{ email: string; password: string }, any, undefined>;
  error?: string | null;
  onClickLogin: () => Promise<void>;
  onClickRegister: () => void;
}

const LoginLayout = ({ role, isLoading, form, error, onClickLogin, onClickRegister }: LoginLayoutProps) => {
  const { register, formState: { errors } } = form;
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <Grid container spacing={4} sx={{ p: isSmallScreen ? 2 : 6, alignItems: 'center' }}>
      <Grid item xs={12} md={6}>
        <Stack direction="column" spacing={3} alignItems="center">
          <img alt="Logo" src={Images.logoLight} style={{ maxHeight: '60px', maxWidth: '150px' }} />
          <Typography variant={isSmallScreen ? 'h4' : 'h2'} textAlign="center">Sign In ({role})</Typography>
          <FormControl fullWidth sx={{ m: 1 }} error={!!errors.email}>
            <InputLabel htmlFor="email">User Email</InputLabel>
            <OutlinedInput
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Enter a valid email',
                },
              })}
              id="email"
              label="User Email"
            />
            {errors.email && <FormHelperText>{errors.email.message as string}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }} error={!!errors.password}>
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              {...register('password', {
                required: 'Password is required',
                minLength: {
                  value: 6,
                  message: 'Password must be at least 6 characters long',
                },
              })}
              type="password"
              id="password"
              label="Password"
            />
            {errors.password && <FormHelperText>{errors.password.message as string}</FormHelperText>}
          </FormControl>
          {error && <FormHelperText error>{error}</FormHelperText>}
          <Button
            disabled={isLoading}
            startIcon={isLoading && <CircularProgress size={16} />}
            onClick={onClickLogin}
            variant="contained"
            fullWidth
          >
            Submit
          </Button>
          {role !== 'Token System' && (
            <Stack
              onClick={onClickRegister}
              sx={{ cursor: 'pointer' }}
              alignItems="center"
              justifyContent="center"
              direction="row"
            >
              <Typography variant="body2">New to our platform?</Typography>
              <Typography variant="body2" marginLeft="2px" component="span">Create an account</Typography>
            </Stack>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} md={6} display="flex" justifyContent="center">
        <Box sx={{ width: isSmallScreen ? '100%' : 'auto', textAlign: 'center' }}>
          <img alt="Banner" src={Images.banner} style={{ maxWidth: '100%', maxHeight: '60vh' }} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginLayout;