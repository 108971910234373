import * as React from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import AppLayout from '../layout/AppLayout';
import LandingLayout from '../pages/LandingPage';
import LoginPage from '../pages/LoginPage';
import SignUpPage from '../pages/SignUpPage';
import ProfilePage from '../pages/doctor/ProfilePage';
import DashboardPage from '../pages/doctor/DashboardPage';
import SettingsPage from '../pages/doctor/SettingsPage';
import LogsPage from '../pages/doctor/LogsPage';
import AdminDoctorPage from '../pages/admin/AdminDoctorPage';
import AccessDeniedPage from '../pages/AccessDeniedPage';
import AdminHospitalPage from '../pages/admin/AdminHospitalPage';
import AdminDashboardPage from '../pages/admin/AdminDashboard';
import RegisterDoctorDetails from '../pages/RegisterDoctorDetails';
import RegisterHospitalDetails from '../pages/RegisterHospitalDetails';
import AdminSettings from '../pages/admin/AdminSettings';
import CallLogsPage from '../pages/hospital/CallLogsPage';
import PaymentPage from '../pages/hospital/PaymentPage';
import ActiveCallsPage from '../pages/hospital/ActiveCallsPage';
import TokenLogsPage from '../pages/hospital/TokenLogsPage';
import DepartmentPage from '../pages/hospital/DepartmentPage';
import ModifyRoomPage from '../pages/hospital/ModifyRoomPage';
import TokenSystemHomePage from '../pages/tokenSystem/TokenSystemHomePage';
import PatientRegistrationPage from '../pages/hospital/PatientRegistrationPage';
import HospitalSettingsPage from '../pages/hospital/SettingsPage';
import ActiveTokenPage from '../pages/hospital/ActiveTokenPage';
import { Roles } from '../constants/Roles';
import { RouterName } from '../constants/RouteName';
import { useUserAuth } from '../provider/AuthProvider';
import DoctorSchedulePage from '../pages/hospital/DoctorSchedulePage';
import Waiting from '../pages/hospital/Waiting';
import { useSocketContext } from '../provider/SocketProvider';
import { isValidJSON } from "../utils/checkJson";
import { toast } from 'react-toastify';
import AdminHardware from '../pages/admin/AdminHardware';

const Router = (): React.JSX.Element => {
  const { user, loading } = useUserAuth();
  const { lastMessage } = useSocketContext();
  const audioFile = require("../assets/notification.wav")

  const notify = (params: string = "something went wrong") => toast(params, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    type: 'info'
  });
  const ALERT_MSG = 'You have received a message from doctor';
  const audioRef = React.useRef(new Audio(audioFile));
  // Audio notification logic based on WebSocket message
  React.useEffect(() => {
    if (lastMessage && isValidJSON(lastMessage.data)) {
      console.log('lastMessage', lastMessage);
      const message = lastMessage.data;
      const msg = JSON.parse(message);
      console.info(msg);

      audioRef.current.muted = true;
      audioRef.current.play().then(() => {
        audioRef.current.pause();
        audioRef.current.muted = false; // Enable sound for further notifications
      });
  
      const playMessage = () => {
        audioRef.current.play().catch((error) => {
          console.error('Audio playback failed:', error);
        });
      };

      if (msg.topic === 'active_call' && user?.id === msg.hospitalId && msg.from === 'doctor' && msg.action === 'start') {
        playMessage()
        setTimeout(() => {
          playMessage();
        }, 5000)
        setTimeout(() => {
          playMessage();
        }, 10000)

        notify(ALERT_MSG)
      }
    }
  }, [lastMessage, user]);

  const getPathByRole = React.useMemo(() => {
    if (user?.role === Roles.admin) {
      return RouterName.admin.dashboard;
    }
    if (user?.role === Roles.doctor) {
      return RouterName.doctor.dashboard;
    }
    if (user?.role === Roles.hospital || user?.role === Roles.tokenSystem) {
      return RouterName.hospital.activeCall;
    }
    return '/auth';
  }, [user]);

  if (loading) {
    return <div>Loading...</div>; // can use any loading component
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/auth" />} />
      <Route path="/" element={user?.role ? <Navigate to={getPathByRole} /> : <Outlet />}>
        <Route path='/auth' index element={<LandingLayout />} />
        <Route path='/auth/login/hospital' element={<LoginPage />} />
        <Route path='/auth/login/doctor' element={<LoginPage />} />
        <Route path='/auth/login/token-system' element={<LoginPage />} />
        <Route path='/auth/login/admin' element={<LoginPage />} />
        <Route path='/auth/register/hospital' element={<SignUpPage />} />
        <Route path='/auth/register/doctor' element={<SignUpPage />} />
        <Route path='/register/doctor/details' element={<RegisterDoctorDetails />} />
        <Route path='/register/hospital/details' element={<RegisterHospitalDetails />} />
        <Route path='/waiting' element={<Waiting />} />
      </Route>
      {user?.role === Roles.tokenSystem && (
        <Route path="/" element={user?.authToken ? <Outlet /> : <Navigate to='/auth' />}>
          <Route path='/token-system' element={<TokenSystemHomePage />} />
        </Route>
      )}
      <Route path="/" element={user?.authToken ? <AppLayout /> : <Navigate to='/auth' />}>
        {user?.role === Roles.doctor && (
          <>
            <Route path='/doctor/profile' element={<ProfilePage />} />
            <Route path='/doctor/dashboard' index element={<DashboardPage />} />
            <Route path='/doctor/settings' element={<SettingsPage />} />
            <Route path='/doctor/logs' element={<LogsPage />} />
          </>
        )}
        {user?.role === Roles.admin && (
          <>
            <Route path='/admin/doctor' element={<AdminDoctorPage />} />
            <Route path='/admin/hospital' element={<AdminHospitalPage />} />
            <Route path='/admin/dashboard' index element={<AdminDashboardPage />} />
            <Route path='/admin/settings' element={<AdminSettings />} />
            <Route path='/admin/hardware' element={<AdminHardware />} />
            <Route path='/admin/zoom' element={<AdminHardware />} />
          </>
        )}
        {user?.role === Roles.hospital && (
          <>
            <Route path='/token/new' element={<PatientRegistrationPage />} />
            <Route path='/token/rooms' element={<ModifyRoomPage />} />
            <Route path='/token/departments' element={<DepartmentPage />} />
            <Route path='/token/active' element={<ActiveTokenPage />} />
            <Route path='/token/logs' element={<TokenLogsPage />} />
            <Route path='/token/active-calls' index element={<ActiveCallsPage />} />
            <Route path='/token/call-logs' element={<CallLogsPage />} />
            <Route path='/token/payment' element={<PaymentPage />} />
            <Route path='/token/settings' element={<HospitalSettingsPage />} />
            <Route path='/token/doctorschedule' element={<DoctorSchedulePage />} />
          </>
        )}
        <Route path='*' element={<AccessDeniedPage />} />
      </Route>
    </Routes>
  );
};

export default Router;
